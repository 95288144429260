<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <div id="lbl-screen-name">
            {{ $t("label.lbl_shippingAddress") }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- データテーブル -->
    <v-form ref="form" lazy-validation>
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          height="130px"
          :hide-default-footer="true"
          @page-count="pageCount = $event"
        >
          <!-- 輸送会社名リストボックス -->
          <template v-slot:[`item.routeCompany`]="{ item, index }">
            <v-autocomplete
              class="truck-autocomplete"
              v-model="carrierCompDivSelected"
              :items="carrierCompList"
              persistent-hint
              dense
              width="170px"
              :ref="'cursorProcd' + index"
            >
              <option disabled value="item.routeCompany"></option>
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span></template
              >
            </v-autocomplete>
          </template>

          <!-- 路線会社名リストボックス -->
          <template v-slot:[`item.shippingcompany`]="{ item, index }">
            <v-autocomplete
              class="truck-autocomplete"
              v-model="deliveryTraderSelected"
              :items="deliveryTraderList"
              persistent-hint
              dense
              width="170px"
              :ref="'cursorProcd' + index"
              v-bind:disabled="activatePulldown()"
            >
              <option disabled value="item.shippingcompany"></option>
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </template>
        </v-data-table>
        <v-row col="6">
          <div class="search-row-exeBtn">
            <div class="btn-area">
              <v-col col="5">
                <v-btn color="primary" class="api-btn" @click="close">
                  {{ $t("label.lbl_choice") }}
                </v-btn>
              </v-col>
            </div>
          </div>
        </v-row>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :firstPageFlag="infoDialog.firstPageFlag"
        />
      </v-container>
    </v-form>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
export default {
  created() {},

  components: {
    Loading,
    SimpleDialog,
  },
  // 親画面から渡される製品コード
  props: {
    truckInfo: { type: Object, default: null },
  },
  data: () => ({
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    // ローディング画面表示フラグ
    loadingCounter: 0,
    deliveryTraderMap: {},
    carrierMap: {},
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    // 一覧
    locationList: [],
    deliveryTraderSelected: "",
    carrierCompDivSelected: "",
    // 検索件数
    totalSearch: "0",
    // 配送会社名を親画面に渡す
    data: "",
    // 路線会社プルダウン
    deliveryTraderList: [],
    // 輸送会社プルダウン
    carrierCompList: [],
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_shippingClassification"),
        value: "routeCompany",
        width: "24%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_routecompany"),
        value: "shippingcompany",
        width: "24%",
        align: "center",
        sortable: false,
      },
    ],
    inputList: [
      {
        shippingcompany: "",
        routeCompany: "",
      },
    ],
  }),
  //この処理を入れないと再描画が行われない。
  // beforeUpdate: function () {
  //   this.init();
  // },
  methods: {
    init() {
      this.carrierCompDivSelected = "";
      this.deliveryTraderSelected = "";
      this.carrierCompDivSelected = this.truckInfo.carrierCompCd;
      this.deliveryTraderSelected = this.truckInfo.deliveryTraderSid;

      this.getMstCode();
      this.getDeliveryTrader();
    },
    //配送会社選択ダイアログを閉じる
    close() {
      if (this.$refs.form.validate()) {
        this.truckInfo.deliveryTraderName = "";
        this.truckInfo.deliveryTraderSid = "";
        if (this.carrierCompDivSelected == "04") {
          if (this.deliveryTraderSelected != null) {
            // 路線業者名
            this.truckInfo.carrierCompName = this.deliveryTraderMap[this.deliveryTraderSelected];
            // 路線業者SID
            this.truckInfo.deliveryTraderSid = this.deliveryTraderSelected;
            this.truckInfo.carrierCompCd = this.carrierCompDivSelected;
          } else {
            // 路線業者名
            this.truckInfo.carrierCompName = "";
            // 路線業者SID
            this.truckInfo.deliveryTraderSid = "";
            this.truckInfo.carrierCompCd = this.carrierCompDivSelected;
          }
        } else {
          this.truckInfo.carrierCompName = this.carrierMap[this.carrierCompDivSelected];
          this.truckInfo.carrierCompCd = this.carrierCompDivSelected;
        }
        this.$emit("click", this.truckInfo);
        this.carrierCompDivSelected = "";
        this.deliveryTraderSelected = "";
      }
    },
    //路線会社プルダウン活性化処理
    activatePulldown() {
      if (this.carrierCompDivSelected == appConfig.CARRIERCOMPDIV.DELIVERY) {
        return false;
      } else {
        this.deliveryTraderSelected = "";
        return true;
      }
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_CARRIER_COMP_DIV);
      Promise.all([reasonList])
        .then((result) => {
          // console.debug("getMstCode() Result", result);

          // 画面の初期値を設定します。
          this.carrierCompList = result[0];
          for (var i = 0; i < this.carrierCompList.length; i++) {
            this.carrierMap[this.carrierCompList[i].value] = this.carrierCompList[i].name;
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 路線業者マスタAPI（路線会社リストボックス）
    getDeliveryTrader() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.truckInfo.suppliersSelected;

      config.params.isMst = "0";
      config.params.searchCategory = "0";
      // console.debug("getDeliveryTrader() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_DELIVERY, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDeliveryTrader() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [{ text: "", name: "", value: "" }];
              jsonData.resIdv.deliveryTrader.forEach((row) => {
                list.push({
                  text: row.deliveryTraderLanguage[0].deliveryTraderName,
                  name: row.deliveryTraderLanguage[0].deliveryTraderName,
                  value: row.deliveryTraderLanguage[0].deliveryTraderSid,
                });
                this.deliveryTraderMap[row.deliveryTraderLanguage[0].deliveryTraderSid] =
                  row.deliveryTraderLanguage[0].deliveryTraderName;
              });

              this.deliveryTraderList = list;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getDeliveryTrader() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    reload() {},
  },
  computed: {},
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
// popup画面の為、本画面ではmargin-leftは別設定
//データテーブル
#listData {
  width: 1000px;
  &.v-data-table--fixed-header ::v-deep {
    //テーブル項目部分
    th {
      text-align: center !important;
      font-size: large !important;
      color: black;
      padding: 0.1rem;
      background: #fbe9db;
      height: 1.75rem !important;
      &:nth-child(0) {
        border-bottom: 1px solid $border-color;
      }
      &:not(:nth-child(0)) {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &:last-child {
        border-right: 1px solid $border-color;
      }
    }
    //テーブルデータ部分
    td {
      height: 3rem !important;
      &:nth-child(1) {
        background: #eef1de;
        border-left: 1px solid $border-color;
        font-size: small !important;
        text-align: center !important;
      }
      &:nth-child(2) {
        text-align: center !important;
      }
    }
  }
}
</style>
